/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getNotification } from "../../../../services/ApiServices";
import { DropdownCustomToggler, DropdownMenu3 } from "../../dropdowns";

export function ListsWidget10({ className }) {
  const [notify, setNotify] = useState([]);
  const notifications = async () => {
    let result;
    try {
      result = await getNotification();
      if (result.success) {
        console.log(result);
        setNotify(result.notification);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    notifications();
  }, []);
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Notifications
          </h3>

          <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-ver" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu3 />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          <div className="mb-6">
            {notify?.map((x) => (
              <div className="d-flex align-items-center flex-grow-1">
                <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input type="checkbox" value="1" />
                  <span></span>
                </label>

                <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div className="d-flex flex-column align-items-cente py-2 w-75">
                    <a
                      href="#"
                      className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                    >
                      {x?.assetId?.title}
                    </a>

                    <span className="text-muted font-weight-bold">
                      {x?.type}
                    </span>
                  </div>

                  <span className="label label-lg label-light-primary label-inline font-weight-bold py-4">
                    {x?.message}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
