import * as requestFromServer from "./collectionsCrud";
import { categorySlice, callTypes } from "./collectionsSlice";
import {
  GetAsset,
  GetCollection,
  GetCollections,
  VerifyCollection,
  GetIdoToken,
  GetInitialNFTofferings,
  GetempAssetsByFilter,
  PostAssetsfilterbydate,
  GetInterestedProperties,
  getArtistByFilter,
  GetCategories,
} from "../../../../../services/ApiServices";
// import { useParams } from "react-router-dom";
const { actions } = categorySlice;

// const {id} = useParams
// export const fetchArtistDetails = (id,queryParams) => async (dispatch) => {
//   let query = "&";
//   let body={sort:"asc"};
//   for (const key in queryParams) {
//     switch (key) {
//       case "sortOrder":
//         query = query.concat("sort=" + queryParams.sortOrder);
//         break;
//       case "pageNumber":
//         query = query.concat("&title="+ queryParams.filter.model +"&page=" + queryParams.pageNumber);
//         break;
//       case "pageSize":
//         query = query.concat("&limit=" + queryParams.pageSize);
//         break;
//         case "filter":
//           if(queryParams.filter.model)
//           body={name:queryParams.filter.model};
//           // body = query.concat("title=" + queryParams.filter.model)
//           if(queryParams.filter.category)
//           body={category:queryParams.filter.category};
//           // query =query.concat("title=" + queryParams.filter.category )
//           break;
//       default:
//         break;
//     } ⬇️
//   }

//   dispatch(actions.startCall({ callType: callTypes.list }));
//   // return requestFromServer
//   let result;
//   try {
//     result = await GetInitialNFTofferings(id,query);
//   } catch (error) {
//     error.clientMessage = "Can't find collections";
//     dispatch(actions.catchError({ error, callType: callTypes.list }));
//   }
//   try {
//     if (result.success)
//     {
//       console.log(result,"result")
//       let totalCount = result.asset.totalDocs;
//       let entities = result.asset.docs;
//       let page =result.asset.page;
//       let totalPages =result.asset.totalPages;
//       console.log(entities,"entities",page,totalPages);
//       dispatch(actions.collectionsFetched({ totalCount, entities,page,totalPages }));
//     }

//   } catch (error) {
//     error.clientMessage = "Can't find collections";
//     dispatch(actions.catchError({ error, callType: callTypes.list }));
//   }
// };
export const fetchCategory = (queryParams, projectId, token) => async (
  dispatch
) => {
  let body = { filter: {}, sort: {}, search: {} };
  for (const key in queryParams) {
    // console.log(queryParams,"ywsa")
    switch (key) {
      case "sortOrder":
        body.sort = { [queryParams.sortField]: queryParams.sortOrder };
        if (queryParams.filter.createdAt)
          body.sort = { createdAt: queryParams.filter.createdAt };
        break;
      case "pageNumber":
        body.page = queryParams.pageNumber;
        break;
      case "pageSize":
        body.limit = queryParams.pageSize;
        break;
      case "filter":
        if (queryParams.filter.model)
          body.search = { firstName: queryParams.filter.model };
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (projectId) {

    let results;
    try {
      body = { type: queryParams?.filter?.status };
      results = await GetCategories(body, projectId, token);
    } catch (error) {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    }
    try {
      if (results.success) {
        let totalCount = results.category.totalDocs;
        let entities = results.category.docs;
   
        dispatch(actions.categoryFetched({ totalCount, entities }));
      }
    } catch (error) {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    }
  } else {


    // return requestFromServer
    let result;
    try {
      result = await GetCategories(body, projectId, token);
    } catch (error) {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    }
    try {
      if (result.success) {
        let totalCount = result.category.totalDocs;
        let entities = result.category?.docs;
        // console.log(entities,"entities");
        dispatch(actions.collectionsFetched({ totalCount, entities }));
      }
    } catch (error) {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    }
  }
};

export const fetchInterestedProperties = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let results;
  try {
    results = await GetInterestedProperties();
  } catch (error) {
    error.clientMessage = "Can't find collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (results.success) {
      let totalCount = results.users.totalDocs;
      let entities = results.users.docs;
      dispatch(actions.collectionsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyCollection = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyCollection(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.collectionDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteCollection(id)
  // .then((response) => {
  //   dispatch(actions.collectionDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete collection";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteCollection = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCollection(id)
    .then((response) => {
      dispatch(actions.collectionDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete collection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const clearCategories = () => (dispatch) => {
  dispatch(actions.clearCategories());
};
export const createCollection = (collectionForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCollection(collectionForCreation)
    .then((response) => {
      const { collection } = response.data;
      dispatch(actions.collectionCreated({ collection }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create collection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCollection = (collection) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCollection(collection)
    .then(() => {
      dispatch(actions.collectionUpdated({ collection }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update collection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCollectionsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCollections(ids, status)
    .then(() => {
      dispatch(actions.collectionsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update collections status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCollections = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCollections(ids)
    .then(() => {
      dispatch(actions.collectionsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete collections";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
