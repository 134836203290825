/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";
import { toAbsoluteUrl } from "../../../_helpers";
import {
  getArtistByFilter,
  getCataloguesdetails,
  getMonthwiseArtist,
} from "../../../../services/ApiServices";
import moment from "moment";
import { BiCategory } from "react-icons/bi";
import avatar from "../../../../images/art.png";
import NumberFormat from "react-number-format";
import { Progress, Tooltip } from "antd";
import "./index.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function ListsWidget14({ className }) {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const [artist, setArtist] = useState([]);

  const getuserCount = async () => {
    let result;
    result = await getCataloguesdetails({}, user.projectId);
    try {
      if (result.success) {
        setArtist(result?.product?.docs);
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e, "err");
    }
  };
  useEffect(() => {
    getuserCount();
  }, []);

  const [showArrow, setShowArrow] = useState(true);
  const [arrowAtCenter, setArrowAtCenter] = useState(false);
  const mergedArrow = useMemo(() => {
    if (arrowAtCenter)
      return {
        arrowPointAtCenter: true,
      };
    return showArrow;
  }, [showArrow, arrowAtCenter]);
  const text = <span>Target</span>;
  const texts = <span>Raised</span>;

  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0  d-flex align-items-center">
          <h3 className="card-title font-weight-bolder text-dark">
            {" "}
            <BiCategory /> &nbsp; Recent Courses
          </h3>
          <div className="align-center gap d-flex">
            {/* <Badge pill variant="dark">
              Total
            </Badge> */}
            {/* <span class="badge badge-dark">Total</span>
            <span class="badge badge-info">Sold</span> */}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2">
          {/* begin::Item */}
          {artist?.slice(0, 4)?.map((x, index) => (
            <div
              className="d-flex flex-wrap align-items-center mb-10"
              key={index}
            >
              {/* begin::Symbol */}
              <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                  className="symbol-label"
                  style={{
                    backgroundImage: `url('${
                      x?.images ? x?.images[0]?.url : avatar
                    }')`,
                  }}
                ></div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                <a
                  href={"/admin/products/edit/" + x?._id}
                  className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                >
                  {x?.name.slice(0, 30) + "...." ?? "--"}
                </a>
                <span className="text-primary font-weight-bold font-size-sm my-1">
                  Price : £ {x?.price}
                </span>{" "}
                {/* <span className="text-dark font-weight-bold font-size-sm my-1">
                  Available Shares : {x?.result?.availableShares}
                </span>{" "} */}
                {/* <div
                    style={{
                      width: 170,
                    }}
                  >
                    <Progress
                      type="Line"
                      size="small"
                      percent={50}
                      steps={
                        x?.result?.totalShares - x?.result?.availableShares
                      }
                      showInfo={true}
                    />
                  </div> */}
                {/* <span className="text-primary font-weight-bold">CoreAd</span> */}
                <span className="text-muted font-weight-bold font-size-sm">
                  Created at: {moment(x?.createdAt).format("DD-MM-YYYY")}
                  {/* <span className="text-primary font-weight-bold">CoreAd</span> */}
                </span>
              </div>
              {/* end::Title */}

              {/* begin::Info */}
              <div className="d-flex align-items-center py-lg-0 py-2">
                <div className="d-flex flex-column text-right">
                  {/* {x?.tag?.map((goals) => ( */}
                  <>
                    {/* <Tooltip
                      placement="rightTop"
                      title={text}
                      arrow={mergedArrow}
                    > */}
                    {/* <span className="text-dark-75 font-weight-bolder font-size-h6 text-info">
                      £{" "}
                      {x?.result?.amount >= 1e3 && x?.result?.amount < 1e6
                        ? (x?.result?.amount / 1e3).toFixed(1) + "K"
                        : x?.result?.amount >= 1e6 && x?.result?.amount < 1e9
                        ? (x?.result?.amount / 1e6).toFixed(1) + "M"
                        : x?.result?.amount >= 1e9 && x?.result?.amount < 1e12
                        ? (x?.result?.amount / 1e9).toFixed(1) + "B"
                        : x?.result?.amount}
                    </span> */}

                    {/* <span className="text-info-75 font-weight-bolder font-size-h6 text-info">
                      £{" "}
                      {parseInt(x?.result?.amount) -
                        parseInt(x?.result?.remainingAmount) >=
                        1e3 &&
                      parseInt(x?.result?.amount) -
                        parseInt(x?.result?.remainingAmount) <
                        1e6
                        ? (
                            (parseInt(x?.result?.amount) -
                              parseInt(x?.result?.remainingAmount)) /
                            1e3
                          ).toFixed(1) + "K"
                        : parseInt(x?.result?.amount) -
                            parseInt(x?.result?.remainingAmount) >=
                            1e6 &&
                          parseInt(x?.result?.amount) -
                            parseInt(x?.result?.remainingAmount) <
                            1e9
                        ? (
                            (parseInt(x?.result?.amount) -
                              parseInt(x?.result?.remainingAmount)) /
                            1e6
                          ).toFixed(1) + "M"
                        : parseInt(x?.result?.amount) -
                            parseInt(x?.result?.remainingAmount) >=
                            1e9 &&
                          parseInt(x?.result?.amount) -
                            parseInt(x?.result?.remainingAmount) <
                            1e12
                        ? (
                            (parseInt(x?.result?.amount) -
                              parseInt(x?.result?.remainingAmount)) /
                            1e9
                          ).toFixed(1) + "B"
                        : parseInt(x?.result?.amount) -
                          parseInt(x?.result?.remainingAmount)}
                 
                   }
                    </span>{" "} */}
                    {/* </Tooltip> */}
                    {/* {x?.result?.availableShares > 0 ? (
                      <span class="badge badge-info mr-5">Open</span>
                    ) : (
                      <span class="badge badge-danger mr-5">Closed</span>
                    )} */}
                  </>
                  {/* ))} */}
                  {/* <span className="text-muted font-size-sm font-weight-bolder">
                    Goals
                  </span> */}
                </div>
              </div>
              {/* end::Info */}
            </div>
          ))}
          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
}
