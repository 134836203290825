import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import "./styles.css";

const DoughnutChart = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const chartData = {
    options: {
      labels: ["MSME", "Large Scale"],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      colors: ["#497174", "#2C74B3"],
      responsive: [
        {
          breakpoint: 580,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // console.log(config.w.config.labels[config.dataPointIndex]);
            console.log("click unit");
            props.getUnit(config.w.config.labels[config.dataPointIndex]);
          },
        },
      },
    },
    series: [50, 55],
  };

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
      />
    </div>
  );
};

export default DoughnutChart;
