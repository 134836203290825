/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { getCounts } from "../../../../services/ApiServices";
import { toAbsoluteUrl } from "../../../_helpers";

export function TilesWidget12(
  props,
  { className, iconColor = "success", widgetHeight = "150px" }
) {
  return (
    <>
      <div
        className={` card card-custom ${className}`}
        style={{ height: widgetHeight, backgroundColor: "#CD7672" }}
      >
        <div className="card-body text-white">
          <span className={`svg-icon svg-icon-3x svg-icon-white`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            />
          </span>
          <div className="font-weight-bolder font-size-h2 mt-3">
            {props.loading ? (
              <div
                className={`text-inverse-white spinner spinner-white font-weight-bolder font-size-h2 mb-3 mt-3 py-4`}
              ></div>
            ) : (
              <>
                {props?.user?.artist >= 1e3 && props?.user?.artist < 1e6
                  ? (props?.user?.artist / 1e3).toFixed(1) + "K"
                  : props?.user?.artist >= 1e6 && props?.user?.artist < 1e9
                  ? (props?.user?.artist / 1e6).toFixed(1) + "M"
                  : props?.user?.artist >= 1e9 && props?.user?.artist < 1e12
                  ? (props?.user?.artist / 1e9).toFixed(1) + "B"
                  : props?.user?.artist}
              </>
            )}
          </div>

          <a
            href="upfront/artist"
            className="text-white text-hover-primary font-weight-bolder font-size-lg mt-1"
          >
            Artists
          </a>
        </div>
      </div>
    </>
  );
}
