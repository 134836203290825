import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register, registerByadmin } from "../_redux/authCrud";
import { useToasts } from "react-toast-notifications";
import { Checkbox } from "antd";

const initialValues = {
  // fullname: "",
  email: "",
  userName: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    userName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }

  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }

  //   return "";
  // };

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: RegistrationSchema,
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     setSubmitting(true);
  //     enableLoading();
  //     register(values.email, values.userName, values.password)
  //       .then(({ data: { authToken } }) => {
  //         props.register(authToken);
  //         disableLoading();
  //         setSubmitting(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setSubmitting(false);

  //         disableLoading();
  //       });
  //   },
  // });
  const { addToast } = useToasts();
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [funError, setFunError] = useState("");
  const [passError, setPassError] = useState("");
  const [userError, setUserNameError] = useState("");
  const [investment, setInvestment] = useState(false);
  const [sales, setSales] = useState(false);
  const history = useHistory();
  const onSubmit = async () => {
    if (!userName) {
      setUserNameError("please enter user name");
    } else if (!email) {
      setUserNameError("");
      setError("please enter the email");
    } else if (!password) {
      setPassError("please enter the password");
    } else if (!confirmPassword) {
      setFunError("please confirm the  password");
    } else {
      setFunError("");
      setLoading(true);
      let result = await registerByadmin(
        userName,
        email,
        password,
        investment,
        sales
      );

      if (result.success) {
        localStorage.setItem("token", result.token);
 
        props.register(result.token);
        setLoading(false);
        history.push("/auth/login");
        return addToast("Successfully registered", {
          appearance: "success",
          autoDismiss: "true",
        });
      } else {
        setLoading(false);


        return addToast(result.message, {
          appearance: "error",
          autoDismiss: "true",
        });
      }
    }
  };
  const emailFunction = async (e) => {
    setError("");
    setEmailError("");
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmail("");
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(e.target.value);
    }
  };
  const passwordFunction = async (e) => {
    // setError("");
    // setEmailError("");
    setPassError("");
    setPassword(e.target.value);
  };
  const onChange = (e) => {
    setInvestment(e.target.checked);
  };
  const onChange1 = (e) => {
    setSales(e.target.checked);
  };
  const confirmPasswordFunction = async (e) => {
    if (password === e.target.value) {
      // setError("");
      // setEmailError("");
      setConfirmPassword(e.target.value);
      setConfirmError("");
    } else {
      setConfirmError("please enter the same password");
    }
  };
  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      {/* <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Full name"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "fullname"
          )}`}
          name="fullname"
        />
      </div> */}
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Name"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6       `}
          name="userName"
          onChange={(e) => {
            setUserName(e.target.value);
          }}
        />
        {userError && <span className="text-danger">{userError}</span>}
      </div>
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Email"
          type="email"
          className={`form-control form-control-solid h-auto py-5 px-6`}
          name="email"
          onChange={(e) => {
            emailFunction(e);
          }}
        />
        {error && <span className="text-danger">{error}</span>}
      </div>

      {/* end: Email */}

      {/* begin: userName */}

      {/* end: userName */}

      {/* begin: Password */}
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Password"
          type="password"
          className={`form-control form-control-solid h-auto py-5 px-6 `}
          name="password"
          onChange={(e) => {
            passwordFunction(e);
          }}
        />

        {passError && <span className="text-danger">{passError}</span>}
      </div>
      {/* end: Password */}

      {/* begin: Confirm Password */}
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Confirm Password"
          type="password"
          className={`form-control form-control-solid h-auto py-5 px-6 `}
          name="changepassword"
          onChange={(e) => {
            confirmPasswordFunction(e);
          }}
        />
        {confirmError && <span className="text-danger">{confirmError}</span>}
      </div>
      <div className="d-flex gap ">
        <div className="form-group">
          <Checkbox onChange={onChange}> Investments</Checkbox>
        </div>
        <div className="form-group">
          <Checkbox onChange={onChange1}> Sales</Checkbox>
        </div>
      </div>

      {funError && <span className="text-danger">{funError}</span>}

      <div className="form-group d-flex flex-wrap flex-center">
        <button
          type="submit"
          className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          onClick={() => onSubmit()}
        >
          <span>Submit</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>

        <Link to="/auth/login">
          <button
            type="button"
            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            Cancel
          </button>
        </Link>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
