/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";

import { ContactMail, SettingsOutlined } from "@material-ui/icons";
import { GrUserSettings } from "react-icons/gr";
import { useToasts } from "react-toast-notifications";
import { SiContactlesspayment, SiMoneygram } from "react-icons/si";
import { BsCardImage, BsFileText, BsPersonCircle } from "react-icons/bs";

import {
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  ref as storageRef,
} from "firebase/storage";

import "../index.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { checkIsActive } from "../../../../_helpers";
import { LOGO } from "../../../../../Config/config";
import {
  MdDiscount,
  MdForum,
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowRight,
  MdLayers,
  MdOutlineForum,
  MdOutlinePayments,
  MdOutlinePrivacyTip,
  MdProductionQuantityLimits,
} from "react-icons/md";

import { FiLogOut, FiUserPlus } from "react-icons/fi";
import { BiCategoryAlt, BiLogInCircle, BiUserCircle } from "react-icons/bi";
import { BsFiletypeDoc, BsFillBuildingsFill } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";

import { AiOutlineDatabase, AiOutlineUser, AiOutlineUserAdd } from "react-icons/ai";
import { ImFeed } from "react-icons/im";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { storage } from "../../../../../services/firebaseConfig";

import "../index.css";
import { profileUpdate } from "../../../../../services/ApiServices";

import * as actions from "../../../../../app/modules/ECommerce/_redux/categoriesAction/collectionsActions";

import { Menu } from "antd";
import { useEffect } from "react";
import { RiAdminFill, RiFeedbackLine } from "react-icons/ri";
import { FaRegCommentDots, FaRegImages, FaUserCircle } from "react-icons/fa";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export function AsideMenuList({ layoutProps }) {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState();
  const [images, setImages] = useState();

  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const update = async () => {
    if (url) {
      let body = {
        profileImage: url,
      };
      let result;
      try {
        result = await profileUpdate(user._id, body, user.projectId);
        if (result.success) {
          return addToast("Successfully Uploaded", {
            appearance: "success",
            autoDismiss: "true",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { SubMenu } = Menu;

  const [openKeys, setOpenKeys] = useState([]);
  const [activeKey, setActiveKey] = useState(null);

  const items = [
    {
      key: "catelog",
      title: "Catalog",
      icon: <BiCategoryAlt />,
      subMenuItems: [
        {
          key: "6",
          title: "Products",
          url: "/admin/products",
          icon: <MdProductionQuantityLimits />,
        },
        {
          key: "7",
          title: "Categories",
          url: "/admin/categories",
          icon: <BiCategory />,
        },

        {
          key: "12",
          title: "Offers",
          url: "/admin/offers",
          icon: <BiCategory />,
        },
      ],
    },
    {
      key: "sales",
      title: "Sales",
      icon: <SiMoneygram />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/orders",
          icon: <BsFillBuildingsFill />,
        },
        // {
        //   key: "10",
        //   title: "Customers",
        //   url: "/admin/users",
        //   icon: <BiUserCircle />,
        // },
        {
          key: "11",
          title: "Payments",
          url: "/admin/payment",
          icon: <SiContactlesspayment />,
        },
      ],
    },
    {
      key: "forums",
      title: "Forums & Discussion",
      icon: <MdOutlineForum />,
      subMenuItems: [
        {
          key: "16",
          title: "Forums",
          url: "/admin/Forums",
          icon: <BiUserCircle />,
        },
        {
          key: "15",
          title: "Forum-Requests",
          url: "/admin/ForumRequests",
          icon: <MdForum />,
        },

        // {
        //   key: "17",
        //   title: "Payments",
        //   url: "/admin/payment",
        //   icon: <SiContactlesspayment />,
        // },
      ],
    },
    {
      key: "sub1",
      title: "Settings",
      icon: <SettingsOutlined />,
      subMenuItems: [
        {
          key: "1",
          title: "Notifications",
          url: "/admin/config/notification",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "2",
          title: "Product Types",
          url: "/admin/config/Producttypes",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "3",
          title: "Payment Gateway",
          url: "/admin/config/Paymentgateway",
          icon: <MdOutlinePayments />,
        },
        {
          key: "4",
          title: "System Config",
          url: "/admin/configuration",
          icon: <ImFeed />,
        },

        {
          key: "5",
          title: "Users Manual",
          url: "/admin/manual",
          icon: <GrUserSettings />,
        },
      ],
    },
  ];
  const miningitems = [
    {
      key: "catelog",
      title: "Catalog",
      icon: <BiCategoryAlt />,
      subMenuItems: [
        {
          key: "6",
          title: "Products",
          url: "/admin/products",
          icon: <MdProductionQuantityLimits />,
        },
        {
          key: "7",
          title: "Categories",
          url: "/admin/categories",
          icon: <BiCategory />,
        },
      ],
    },
    {
      key: "sales",
      title: "Sales",
      icon: <SiMoneygram />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/orders",
          icon: <BsFillBuildingsFill />,
        },
        {
          key: "10",
          title: "Users",
          url: "/admin/users",
          icon: <BiUserCircle />,
        },
        {
          key: "11",
          title: "Payments",
          url: "/admin/payment",
          icon: <SiContactlesspayment />,
        },
      ],
    },
    {
      key: "sub1",
      title: "Settings",
      icon: <SettingsOutlined />,
      subMenuItems: [
        {
          key: "1",
          title: "Notifications",
          url: "/admin/config/notification",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "2",
          title: "Product Types",
          url: "/admin/config/Producttypes",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "3",
          title: "Payment Gateway",
          url: "/admin/config/Paymentgateway",
          icon: <MdOutlinePayments />,
        },
        {
          key: "4",
          title: "System Config",
          url: "/admin/configuration",
          icon: <ImFeed />,
        },

        {
          key: "5",
          title: "Users Manual",
          url: "/admin/manual",
          icon: <GrUserSettings />,
        },
      ],
    },
  ];
  const stmitems = [
    {
      key: "catelog",
      title: "Catalog",
      icon: <BiCategoryAlt />,
      subMenuItems: [
        {
          key: "6",
          title: "Courses",
          url: "/admin/products",
          icon: <MdProductionQuantityLimits />,
        },
        {
          key: "7",
          title: "Categories",
          url: "/admin/categories",
          icon: <BiCategory />,
        },
      ],
    },
    {
      key: "sales",
      title: "Sales",
      icon: <SiMoneygram />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/orders",
          icon: <BsFillBuildingsFill />,
        },

        // {
        //   key: "10",
        //   title: "Customers",
        //   url: "/admin/users",
        //   icon: <BiUserCircle />,
        // },
        {
          key: "11",
          title: "Payments",
          url: "/admin/payment",
          icon: <SiContactlesspayment />,
        },
        {
          key: "19",
          title: "Discounts",
          url: "/admin/discount",
          icon: <MdDiscount />,
        },
      ],
    },

    {
      key: "subuser",
      title: "Customers",
      icon: <AiOutlineUser />,
      subMenuItems: [
        {
          key: "1",
          title: "Customers",
          url: "/admin/users",
          icon: <FaUserCircle />,
        },
      ],
    },
    {
      key: "subadmin",
      title: "Users",
      icon: <RiAdminFill />,
      subMenuItems: [
        {
          key: "1",
          title: "Users",
          url: "/admin/adminusers",
          icon: <FiUserPlus />,
        },

      ],
    },
    {
      key: "feedback",
      title: "Feedback & Enquires",
      icon: <RiFeedbackLine />,
      subMenuItems: [
        {
          key: "1",
          title: "User Feedback",
          url: "/admin/feedback",
          icon: <FaRegCommentDots />,
        },
        {
          key: "30",
          title: "Enquiry",
          url: "/admin/contact",
          icon: <ContactMail />,
        },
      ],
    },
    {
      key: "terms",
      title: "Terms & Privacy",
      icon: <AiOutlineDatabase />,
      subMenuItems: [
        {
          key: "1",
          title: "Term Of Use",
          url: "/admin/terms",
          icon: <BsFileText />,
        },
        {
          key: "30",
          title: "Privacy Policy",
          url: "/admin/privacy",
          icon: <MdOutlinePrivacyTip />,
        },
      ],
    },
    {
      key: "image",
      title: "Banner Images",
      icon: <BsCardImage />,
      subMenuItems: [
        {
          key: "2",
          title: "Banner Images",
          url: "/admin/image",
          icon: <FaRegImages />,
        },
      ],
    },
    // {
    //   key: "chat",
    //   title: "Chat",
    //   icon: <RiFeedbackLine />,
    //   subMenuItems: [
    //     {
    //       key: "1",
    //       title: "Chat",
    //       url: "/admin/chatbot",
    //       icon: <FaRegCommentDots />,
    //     },
    //   ],
    // },
    // {
    //   key: "sub1",
    //   title: "Settings",
    //   icon: <SettingsOutlined />,
    //   subMenuItems: [
    //     {
    //       key: "1",
    //       title: "Notifications",
    //       url: "/admin/config/notification",
    //       icon: <MdOutlineNotificationsActive />,
    //     },
    //     // {
    //     //   key: "2",
    //     //   title: "Product Types",
    //     //   url: "/admin/config/Producttypes",
    //     //   icon: <MdOutlineNotificationsActive />,
    //     // },
    //     // {
    //     //   key: "3",
    //     //   title: "Payment Gateway",
    //     //   url: "/admin/config/Paymentgateway",
    //     //   icon: <MdOutlinePayments />,
    //     // },
    //     // {
    //     //   key: "4",
    //     //   title: "System Config",
    //     //   url: "/admin/configuration",
    //     //   icon: <ImFeed />,
    //     // },

    //     // {
    //     //   key: "5",
    //     //   title: "Users Manual",
    //     //   url: "/admin/manual",
    //     //   icon: <GrUserSettings />,
    //     // },
    //   ],
    // },
  ];

  const handleClick = (url, key) => {
    history.push(url);
    setActiveKey(key);
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const findActiveKey = (url) => {
    for (const item of items) {
      for (const subItem of item.subMenuItems) {
        if (subItem.url === url) {
          return item.key;
        }
      }
    }
    return null;
  };
  // 648ff13fe0a4e803ae555e31
  // Set the active key based on the current URL
  useEffect(() => {
    const currentMenuItem = items.find((item) =>
      location.pathname.includes(item.title)
    );
    if (currentMenuItem) {
      setActiveKey(currentMenuItem.key);
    }
  }, [location.pathname]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <Link to="/" className="flex-column-auto pb-lg-0 pb-10">
          {!images && (
            <img
              alt="Logo"
              className="d-flex mx-auto mb-5"
              src={user.profileImage ? user.profileImage : LOGO}
              style={{ width: "60%", maxWidth: "60%" }}
            />
          )}
        </Link>
        {/* {!images && <li className=" admin ">Admin</li>} */}

        <li
          style={{ top: "12px" }}
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
              <MdLayers />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <hr></hr>
        {/* <li
          className={`menu-item ${getMenuItemActive("/admin/adminDetails", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/adminDetails">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Pencil.svg")} />
            </span>
            <span className="menu-text">Admin</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/admin/empuser", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/empuser">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {user.projectId === "648ff13fe0a4e803ae555e31" ? (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname]}
          >
            {items.map((item) => (
              <SubMenu key={item.key} title={item.title} icon={item.icon}>
                {item.subMenuItems.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    onClick={() => {
                      handleClick(subItem.url, item.key);
                    }}
                    icon={subItem.icon}
                    className={
                      location.pathname.indexOf(subItem.url) === 0
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    {subItem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        ) : user.projectId === "648701c78a0d5cb83ec3b20a" ? (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname]}
          >
            {stmitems.map((item) => (
              <SubMenu key={item.key} title={item.title} icon={item.icon}>
                {item.subMenuItems.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    onClick={() => {
                      handleClick(subItem.url, item.key);
                    }}
                    icon={subItem.icon}
                    className={
                      location.pathname.indexOf(subItem.url) === 0
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    {subItem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        ) : (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname]}
          >
            {miningitems.map((item) => (
              <SubMenu key={item.key} title={item.title} icon={item.icon}>
                {item.subMenuItems.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    onClick={() => {
                      handleClick(subItem.url, item.key);
                    }}
                    icon={subItem.icon}
                    className={
                      location.pathname.indexOf(subItem.url) === 0
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    {subItem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        )}
        {/* <hr />
        <li
          className={`menu-item ${getMenuItemActive("/admin/docs")},           
          ${getMenuItemActive(`/admin/docs/detail/:id`)},
                `}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/docs">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              <BsFiletypeDoc />
            </span>
            <span className="menu-text"> Api Documentation</span>
          </NavLink>
        </li> */}
        <hr></hr>
        <li
          onClick={() => {
            localStorage.removeItem("token");
            dispatch(actions.clearCategories({}));
          }}
          className={`menu-item ${getMenuItemActive("/logout", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logout">
          <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
              <BiLogInCircle />
            </span>
            <span className="menu-text">Sign Out</span>
          </NavLink>
          {/* <NavLink
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </NavLink> */}
        </li>
        {show && (
          <>
            {/* <li className="menu-section  ">
              <h4 className="menu-text">Settings</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li> */}
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/adminDetails"
              )},           
          ${getMenuItemActive(`/admin/products/detail/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/adminDetails">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <AiOutlineUserAdd />
                </span>
                <span className="menu-text"> Users</span>
              </NavLink>
            </li>{" "}
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/notification"
              )},           
          ${getMenuItemActive(`/admin/configuration/notification/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/notification">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <MdOutlineNotificationsActive />
                </span>
                <span className="menu-text"> Notifications</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/Producttypes"
              )},           
          ${getMenuItemActive(`/admin/configuration/Producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/Producttypes">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <MdOutlineNotificationsActive />
                </span>
                <span className="menu-text"> Product types</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/Paymentgateway"
              )},           
          ${getMenuItemActive(`/admin/configuration/producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/Paymentgateway">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <MdOutlinePayments />
                </span>
                <span className="menu-text"> Payment Gateway</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/producttypes"
              )},           
          ${getMenuItemActive(`/admin/configuration/Producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/configuration">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <ImFeed />
                </span>
                <span className="menu-text"> System Config</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/Profile"
              )},           
          ${getMenuItemActive(`/admin/configuration/producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/Profile">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                </span>
                <span className="menu-text">Profile</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/manual"
              )},           
          ${getMenuItemActive(`/admin/manual/detail/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/manual">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <GrUserSettings />
                </span>
                <span className="menu-text"> Users Manual</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

{
  /* <li
className={`menu-item menu-item-submenu ${getMenuItemActive(
  "/error",
  true
)}`}
aria-haspopup="true"
data-menu-toggle="hover"
>
<NavLink className="menu-link menu-toggle" to="/error">
  <span className="svg-icon menu-icon">
    <SVG
      src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
    />
  </span>
  <span className="menu-text">Error Pages</span>
  <i className="menu-arrow" />
</NavLink>
<div className="menu-submenu ">
  <i className="menu-arrow" />
  <ul className="menu-subnav">
    <li className="menu-item  menu-item-parent" aria-haspopup="true">
      <span className="menu-link">
        <span className="menu-text">Error Pages</span>
      </span>
    </li>

    <li
      className={`menu-item ${getMenuItemActive("/error/error-v1")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v1">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 1</span>
      </NavLink>
    </li>
   
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v2")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v2">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page -2</span>
      </NavLink>
    </li>
 
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v3">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 3</span>
      </NavLink>
    </li>
   
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v4")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v4">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 4</span>
      </NavLink>
    </li>

    <li
      className={`menu-item ${getMenuItemActive("/error/error-v5")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v5">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 5</span>
      </NavLink>
    </li>
 
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v6")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v6">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 6</span>
      </NavLink>
    </li>
  </ul>
</div>
</li> */
}
