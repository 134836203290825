const baseUrl =
  // "http://localhost:3200/v1/";

  "https://jxkh6l5zp8.execute-api.eu-west-1.amazonaws.com/prod/v1/";
// "https://8ba1v9t99k.execute-api.eu-west-1.amazonaws.com/dev/v1/"

const stmProBaseUrl =
  "https://6virf3xsh6.execute-api.eu-west-1.amazonaws.com/dev/v1/";

// -------------------- Asset --------------------
export async function GetAssets(params, body) {
  let response;
  try {
    response = await fetch("/v1/assets/filter" + params, {
      // response = await fetch("/v1/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAsset(id) {
  let response;
  try {
    response = await fetch("/v1/asset/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetsByCollection(id, params) {
  let response;
  try {
    response = await fetch("/v1/assets/collection/" + id + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function carouselimageadd(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}image/update/${id}`, {
      method: "Put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function carouselimaghide(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}carousel/deactive/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function carouselimagshow(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}carousel/active/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAssetsByFilter(id, body) {
  let response;
  try {
    response = await fetch("/v1/assets/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetordsByFilter(id, body) {
  let response;
  try {
    response = await fetch("/v1/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ collectionId: id, type: body }),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- TOKEN ID --------------------
export async function GetTokenId() {
  let response;
  try {
    response = await fetch("/v1/asset/getTokenId", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- USER --------------------
export async function GetUsers(body) {
  let response;

  try {
    response = await fetch(`${baseUrl}users/filter`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getsample() {
  let response;
  try {
    response = await fetch(`https://hrms.wisebms.in/v1/apartment/details`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCount() {
  let response;
  try {
    response = await fetch(`${baseUrl}typeCount`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAdminUsers(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/AllUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateAdmin(body) {
  let response;
  try {
    response = await fetch("/v1/admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Login(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Register(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function SignatureLogin(signature) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/signatureLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        signature: signature,
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAdmin(token) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetUser(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}users/filter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COLLECTION --------------------
export async function CreateCollection(body) {
  let response;
  try {
    response = await fetch("/v1/createCollection", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateNFT(body) {
  let response;
  try {
    response = await fetch("/v1/createAsset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function createSub(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}createsubcategory  `, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ImportCollection(address) {
  let response;
  try {
    response = await fetch("/v1/assets/import/" + address, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateCollection(id, body) {
  let response;
  try {
    response = await fetch("/v1/collection/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetCollections(query) {
  let response;
  try {
    response = await fetch("/v1/assets/collection/filter" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCollection(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyCollection(id, token) {
  let response;
  try {
    response = await fetch("/v1/collection/verify/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COMMENT --------------------
export async function GetComments(id) {
  let response;
  try {
    response = await fetch("/v1/comments/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- Reports --------------------
export async function GetReports(params, body) {
  let response;
  try {
    response = await fetch("/v1/reports/" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetReport(id) {
  let response;
  try {
    response = await fetch("/v1/report/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COMMENT --------------------
export async function GetCategories({}, projectId, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}adminCategories?projectId=${projectId}`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetIdoToken(id, params, body) {
  let response;
  try {
    response = await fetch(`/emp/getAssetssAdmin?categoryId=${id}` + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetFinancialAssets(id, query) {
  let response;
  try {
    response = await fetch(`/emp/getAssetss?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetInitialNFTofferings(id, query) {
  let response;
  try {
    response = await fetch(`/emp/getAssetssAdmin?categoryId=${id}` + query, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetManagedFunds(id) {
  let response;
  try {
    response = await fetch(`/emp/getAssetss?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetIdoTokenById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}investor/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCategoryByid(id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}category/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getArtistById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}artist/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetInoTokenById(id) {
  let response;
  try {
    response = await fetch(`/emp/GetassetById/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostAssets(body, token) {
  try {
    const response = await fetch(`${baseUrl}product`, {
      method: "POST",
      // body:data,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400) {
      var errorResponse = await response.json();

      throw new Error(errorResponse.error);
    } else if (response.status === 500) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
    {
      return response;
    }
  } catch (e) {
    throw e;
  }
}
export async function createcategory(body, token) {
  try {
    const response = await fetch(`${baseUrl}category`, {
      method: "POST",
      // body:data,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    } else if (response.status === 500) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
    {
      return response;
    }
  } catch (e) {
    throw e;
  }
}

export async function GetSubcategoriesById(id) {
  let response;
  try {
    response = await fetch(`/v1/GetSubcategories?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Idodelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function adminDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function idoUpdate(body, _id) {
  let response;
  try {
    response = await fetch(`/emp/asset/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function propertyRankUpdate(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/updateUser/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function productUpdate(id, body, projectId, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}product/${id}?projectId=${projectId}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function profileUpdate(id, body, projectid) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}admin/update/${id}?projectId=${projectid}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAllAdmin(id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetOrderUser(id, projectId, page) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}order/user/${id}?projectId=${projectId}&type=buy&page=${page}&limit=10`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //Authorization: token,
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function inoUpdate(body, id) {
  let response;
  try {
    response = await fetch(`/emp/asset/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetActive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/activate/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetDeactive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/deactivate/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetempAssetsByFilter(body) {
  let response;
  try {
    response = await fetch(
      "https://realestate.smartchain.in:3200/v1/assets/filters",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetLogo() {
  let response;
  try {
    response = await fetch(
      `https://securrencyuat.zagtrader.com/API/TickerLogo.php?tickerId=36855`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetZagdetail(id) {
  let response;
  try {
    response = await fetch("/emp/zagassetById/" + id, {
      // mode: 'no-cors',
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEmpadmin(body) {
  let response;
  try {
    response = await fetch("/v1/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEmpadminById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetdasActive(id) {
  let response;
  try {
    response = await fetch(`/emp/zagasset/activate/${id}`, {
      //emp/asset/dasactivate
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function assetdasactive(data) {
  let response;
  try {
    response = await fetch(`/emp/zagasset/activate`, {
      ///emp/asset/dasdeactivate
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ data: data }),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetdasDeactive(data) {
  let response;
  try {
    response = await fetch(`/emp/zagasset/deactivate`, {
      ///emp/asset/dasdeactivate
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ data: data }),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetZaglink() {
  let response;
  try {
    response = await fetch(
      "/API/Backend/GetCompanyValuationData.php?token=6fc1aa548194c8c67d535b2bd70844b7293420bc9c052402f3061e3a21a61bb4&market_symbol=ADX&ticker_symbol=ADPORTS",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetZagmarket(body) {
  let response;
  try {
    response = await fetch("/emp/filter/Assets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetCollectionr(id, body) {
  let response;
  try {
    response = await fetch("/emp/filter/newzagAssets?exchangeId=" + id, {
      // /emp/filter/zagAssets?exchangeId=  /emp/filter/zagAssets?
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostAssetsfilterbydate(body) {
  let response;
  try {
    response = await fetch("/emp/assets/period", {
      // /emp/filter/zagAssets?exchangeId=  /emp/filter/zagAssets?
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetActivefund(id) {
  let response;
  try {
    response = await fetch(`/emp/singleasset/activate/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetDeactivefund(id) {
  let response;
  try {
    response = await fetch(`/emp/singleasset/deactivate/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function Collectionassetdelete(id) {
  let response;
  try {
    response = await fetch(`/v1/collection/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function collectionassetUpdate(body, id) {
  let response;
  try {
    response = await fetch(`/v1/assets/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function Collassetdelete(id) {
  let response;
  try {
    response = await fetch(`/v1/asset/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetliveCollections() {
  let response;
  try {
    response = await fetch("/v1/collections/top", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function zagUpdate(body, id) {
  let response;
  try {
    response = await fetch(`http://54.145.51.238:3200/emp/zagnewAssets/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetOwnedbywallet(walletaddr) {
  let response;
  try {
    response = await fetch(`/v1/user/assets/${walletaddr}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(walletaddr),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetInterestedProperties() {
  let response;
  try {
    response = await fetch(`${baseUrl}getinterest`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCount() {
  let response;
  try {
    response = await fetch(`${baseUrl}getCount`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getInterestedByFilter(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}interest/filter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvestorsByFilter(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/user?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response?.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetadminUsers(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/user?projectId=${projectId}`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        //Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getInvestors() {
  let response;
  try {
    response = await fetch(`${baseUrl}investors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getOrders(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}orders?projectId=${projectId}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getArtistByFilter(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}getArtist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getTransactionFee(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}get/all/investment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUsersDayWise() {
  let response;
  try {
    response = await fetch(`${baseUrl}users/daywise`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getMonthwiseArtist() {
  let response;
  try {
    response = await fetch(`${baseUrl}users/monthwiseArtist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCounts(projectId) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}admin/user/count?projectId=${projectId}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getCataloguesdetails(body, projectid, token) {
  let response;

  try {
    response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response?.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getTransactionDetails(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}getTransactions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUsersMonthWise() {
  let response;
  try {
    response = await fetch(`${baseUrl}users/monthwise`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response?.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getGenderCount() {
  let response;
  try {
    response = await fetch(`${baseUrl}getGenderCount`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getCatalogueByid(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}product/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvestmentAmount(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}transactions/asset/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getOrderById(id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}order/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getDetailById(id, page, pages) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}admins/asset/${id}?page=${page ? page : pages}`,
      {
        method: "GET",
        headers: {
          "content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getInvestmentsByassetId(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}get/investment/asset/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getUserBalance(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}balance/user/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCatalogueByuserId(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/user/${id}?page=${page}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getInvestorBycatalogue(id, page) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}admin/transactions/user/${id}?page=${page}`,
      {
        method: "GET",
        headers: {
          "content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getChartValue(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/chart/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getTotalInvestmentValue(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}transactions/amount/total/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getChartCountByuser(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}investors/chart/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getPortFolio(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}invset/admin/user/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getPercentage() {
  let response;
  try {
    response = await fetch(`${baseUrl}getpercentage`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateproductinCategory(body, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}multipleproduct`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function updateMultipleCategries(body, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}multipleCategories`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getNotification() {
  let response;
  try {
    response = await fetch(
      `${baseUrl}configuration/6426a03c1c6666c9ea1ff0d9`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateNotification(body) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}configuration/update/6426a03c1c6666c9ea1ff0d9`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updatecategory(id, body, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}category/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getTopInvestment() {
  let response;
  try {
    response = await fetch(`${baseUrl}transactions/investment`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function productdelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}product/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function categoryDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}category/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getAllProducts(page, projectId, name) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}products?page=${
        page ? page : 1
      }&projectId=${projectId}&name=${name}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function orderUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}order/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getPayment(body, projectId, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}payment?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getForumRequests(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}forumRequests?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getForums(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}forums?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function requestUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}forum/request/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateUser(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/update/${id}?`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getUserById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getOffers(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}offers?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function OfferUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}offer/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function feedbackUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}feedback/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getOffersByid(id, projectId) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}offer/product/${id}?projectId=${projectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        // body: JSON.stringify(body)
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function passwordUpdate(body, id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/password/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    return await message;
  }
}
export async function updateProject(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}project/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ProjectByid(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}project/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ForumDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}forum/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getFeedbacks(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}feedback/filter?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getContacts(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}contact/filter?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostProCourse(body) {
  try {
    const response = await fetch(`${stmProBaseUrl}course`, {
      method: "POST",
      // body:data,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400) {
      var errorResponse = await response.json();

      throw new Error(errorResponse.error);
    } else if (response.status === 500) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
    {
      return response;
    }
  } catch (e) {
    throw e;
  }
}

export async function stmCourseUpdate(id, body) {
  let response;
  try {
    response = await fetch(`${stmProBaseUrl}course/stm/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAllAdminUsers(projectId, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/user?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getDiscounts(name) {
  let response;
  try {
    response = await fetch(`${baseUrl}discount?name=${name ? name : ""}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function createDiscounts(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}discount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateDiscount(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}discount/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function deleteCoupon(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}discount/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCouponByid(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}discount/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export const ChatOpenAi = async (body) => {
  let result, errResponse;
  try {
    result = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 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",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (result.status === 200) {
    return await result.json();
  } else {
    let message = await result.json();
    throw new Error(message.error);
  }
};

export async function GetAllAdminUserByProjectId(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/all?projectId=${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function AdminRegister(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function deleteAdmin(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function deleteUserByAdmin(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response?.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function deleteCategoryFromProduct(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}deleteProduct`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function deleteProductFromCategory(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}deleteCategory`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getAdminById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/user/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function createTerms(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}term`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetTerms() {
  let response;
  try {
    response = await fetch(`${baseUrl}terms`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function editTerms(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}term/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function createPrivacy(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}privacy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetPrivacy() {
  let response;
  try {
    response = await fetch(`${baseUrl}get/privacy`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function editPrivacy(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}privacy/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function updateUserPasswordByAdmin(id, body, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}update/userPassword/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    return await message;
  }
}

export async function createCustomerByAdmin(token, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/user/create`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 400) {
    return await response.json();
  } else {
    let message = await response.json();
    return await message;
  }
}

export async function createOrderByAdmin(token, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/order`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    return await message;
  }
}

export async function getAvailableProduct(id,page,name) {
  let response;
  try {
    response = await fetch(`${baseUrl}products/available/${id}?page=${page}&name=${name}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    return await message;
  }
}

export async function deleteOrder(id,body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/order/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}