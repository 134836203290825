/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { getGenderCount } from "../../../../services/ApiServices";
import { Pie } from "react-chartjs-2";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./index.css";
am4core.useTheme(am4themes_animated);

export function StatsWidget11({ className, symbolShape, baseColor }) {
  const uiService = useHtmlClassService();
  const [counts, setCounts] = useState([]);
  const [categorys, setCategorys] = useState({
    lables: [],
  });
  const [result, setResult] = useState([]);
  const getCount = async () => {
    let result;

    // result = await getGenderCount();
    try {
      if (result) {
        let array = [];
        let arrays = [];
        setResult(result.user);
        result.user.map((x) => {
          array.push(x.count);
        });

        setCounts(array);

        result.user.map((x) => {
          arrays.push(x._id);
        });
        const uniqueNames = arrays.filter((val, id, array1) => {
          return array1.indexOf(val) === id;
        });
        const distinctData = [
          ...new Set(uniqueNames.map((item) => item.toUpperCase())),
        ];

        setCategorys(distinctData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCount();
  }, []);
  am4core.ready(function() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chart = am4core.create("chartdiv", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;

    // chart.legend = new am4charts.Legend();
    chart.colors.list = [
      am4core.color("#845EC2"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
    ];
    chart.data = [
      {
        country: "Male",
        litres: 201.1,
      },
      {
        country: "Female",
        litres: 165.8,
      },
      {
        country: "Others",
        litres: 165.8,
      },
    ];


    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "litres";
    series.dataFields.category = "country";
    series.dataFields.fill = "color";
  });
  const data = {
    datasets: [
      {
        data: counts,
        backgroundColor: ["#7FBCD2", "#C689C6", "#F68989", "#FFE6BC"],
        borderColor: ["#7FBCD2", "#C689C6", "#F68989", "#FFE6BC"],
        borderWidth: 1,
      },
    ],
    labels: categorys,
  };
  return (
    // <div className={`card card-custom ${className}`}>
    //   <Pie data={data} />
    // </div>
    <div className={`card card-custom ${className} `}>
      <div id="chartdiv" className="chart"></div>
    </div>
  );
}
