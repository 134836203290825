import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login, signatureLogin } from "../_redux/authCrud";
import { useToasts } from "react-toast-notifications";
import Web3 from "web3";
import { ethers } from "ethers";
import {
  EMAIL,
  PASSWORD,
  SIGNATURE_MESSAGE_KEY,
} from "../../../../Config/config";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  let history = useHistory();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const getSignature = async () => {
    if (!window.ethereum)
      return addToast("Metamask is not found.", {
        appearance: "error",
        autoDismiss: "true",
      });
    setSignatureLoading(true);
    var message = SIGNATURE_MESSAGE_KEY;
    var hash = Web3.utils.sha3(message);
    let signature;
    try {
      await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      try {
        signature = await signer.signMessage(hash);
      } catch (e) {
        setSignatureLoading(false);
        return addToast("Signin Failed", {
          appearance: "error",
          autoDismiss: "true",
        });
      }
    } catch (e) {
      return console.log(e);
    }
    // SignatureLogin(signature);
  };
  const adminlogin = async () => {
    setLoading(true);
    if (loading) {
      return;
    }
    if (!email) {
      setError("Please enter the email!");
    } else if (!password) {
      setError("Please enter the password!");
    } else {
      let result = await login(email, password);

      if (result.success) {
        localStorage.setItem("token", result.token);
        props.login(result.token);
        setLoading(false);
        disableLoading();
      } else {
        disableLoading();
        return addToast(result.message, {
          appearance: "error",
          autoDismiss: "true",
        });
      }
    }
  };

  const emailFunction = async (e) => {
    setError("");
    setEmailError("");
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(e.target.value);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then((data) => {
            if (data.success) {
              disableLoading();
              props.login(data.token);
            } else {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            }
          })
          .catch((e) => {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        {/* <p className="text-muted font-weight-bold">
          Signin into MetaMask
        </p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        onSubmit={adminlogin}
      >
        {/* {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )} */}

        <div className="form-group fv-plugins-icon-container">
          <label>Email</label>
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            style={{ borderColor: "rgb(36 173 219)" }}
            onChange={(e) => {
              emailFunction(e);
            }}
            // {...formik.getFieldProps("email")}
          />
          {emailError && <span className="text-danger">{emailError}</span>}
          {/* {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-danger">{formik.errors.email}</div>
            </div>
          ) : null} */}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label>Password</label>
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            style={{ borderColor: "rgb(36 173 219)" }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            // {...formik.getFieldProps("password")}
          />
          {/* {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-danger">{formik.errors.password}</div>
            </div>
          ) : null} */}
        </div>
        {/* <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
           <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> 
     
        </div> */}
      </form>
      {/* <Link to="/dashboard"> */}
      {error && <span className="text-danger">{error}</span>}
      {/* <div>
        If you don't have an account. Please{" "}
        <Link to="/auth/register" style={{ color: "#0060ff" }}>
          register
        </Link>
      </div> */}
      {!loading && (
        <button
          // id="kt_login_signin_submit"
          type="submit"
          // disabled={formik.isSubmitting}
          className={`btn btn-primary font-weight-bold px-9 py-4 my-3 d-block my-0 mx-auto `}
          onClick={() => {
            adminlogin();
          }}
          style={{color:'#FFFFFF',backgroundColor:'#281E5D'}}
        >
          <span>Sign In</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
      )}

      {loading && (
        <button
          // id="kt_login_signin_submit"
          type="submit"
          // disabled={formik.isSubmitting}
          className={`btn btn-primary font-weight-bold px-9 py-4 my-3 d-block my-0 mx-auto `}
        >
          <span>Sign In</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
      )}

      {/* </Link> */}
      {/* <button
        // id="kt_login_signin_submit"
        // type="submit"
        // disabled={formik.isSubmitting}
        className={`btn btn-outline-warning font-weight-bold px-8 py-2 my-3 mx-auto d-flex align-items-center`}
        onClick={getSignature}
      >
      <img
                  alt="Logo"
                  className="max-h-40px mr-2"
                  src={toAbsoluteUrl("/media/logos/MetaMask.png")}
                />
        <span className="h6 mb-0">Login with MetaMask</span>
        {loading && <span className="ml-3 spinner spinner-white"></span>}
      </button> */}
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
