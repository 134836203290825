/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useHistory, useLocation, Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { shallowEqual, useSelector } from "react-redux";
import { MdFileCopy } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import { useRef } from "react";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Divider, Popover, Select } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import { BiUserCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import * as actions from "../../../../app/modules/ECommerce/_redux/categoriesAction/collectionsActions";
import { EmailOutlined } from "@material-ui/icons";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);

    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  const { addToast } = useToasts();
  const myRef = useRef();
  const [isCopied, setIsCopied] = useState(false);

  const functtocopy = () => {
    // navigator.clipboard.writeText(user.wallets[0])
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    return addToast("Address Copied", {
      appearance: "success",
      autoDismiss: "true",
    });
  };
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const [year, setYear] = useState("2023");
  const [period, setPeriod] = useState("January");
  const handleChange = (value) => {
    setYear(value);
    history.push({
      pathname: history.location.pathname,
      search: `?year=${value}&period=${period}`,
    });
  };
  const dispatch = useDispatch();

  const handleChange1 = (value) => {
    setPeriod(value);
    history.push({
      pathname: history.location.pathname,
      search: `?year=${year}&period=${value}`,
    });
  };
  const names = user?.email.split(" ");
  const firstLetters = names.map((name) => name.charAt(0,1));
  return (
    <div
      id="kt_subheader"
      className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}

        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
        </div>
        <div className="ml-auto text-center">
          <div className="d-flex align-items-center gap mr-5">
            <span>
              <Popover
                placement="bottom"
                className="popup"
                content={
                  <div
                  // onClick={() => {
                  //   localStorage.removeItem("token");
                  // }}
                  >
                    <div className="fwt d-flex gap align-items-center mb-2">
                      <div className="brdr text-uppercase" style={{color:'orange'}}>{firstLetters}</div>
                      {user.email}
                    </div>
                    <Divider />
                    <Link to="/admin/profile">
                      <div className="font-weight-bold d-flex gap align-items-center mb-7" style={{color:"#281E5D",fontWeight:'bold'}}>
                        <BiUserCircle
                          style={{ color: "orange", fontSize: "25px" }}
                        />
                        Profile
                      </div>
                    </Link>
                    <Link to="/logout">
                      <div className="font-weight-bold d-flex gap align-items-center mb-2 ml-1" style={{color:"#281E5D",fontWeight:'bold'}}>
                      <FiLogOut 
                          style={{ color: "orange", fontSize: "25px" }}
                        />
                        Sign Out
                      </div>
                    </Link>
                  </div>
                  
                }
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
              >
                <BiUserCircle style={{ fontSize: "25px" }} />{" "}
              </Popover>
            </span>
            <span className="text-capitalize text-primary">
              {user?.userName}
            </span>
          </div>
        </div>
        {/* <div className="ml-auto ml-lg-0 d-flex gap">
          <div className="mr-4">
            <div>Year</div>
            <>
              <Select
                defaultValue="2023"
                style={{
                  width: "fit-content",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "2023",
                    label: "2023",
                  },
                  {
                    value: "2022",
                    label: "2022",
                  },
                  {
                    value: "2021",
                    label: "2021",
                  },
                  {
                    value: "2020",
                    label: "2020",
                  },
                  {
                    value: "2019",
                    label: "2019",
                  },
                ]}
              />
            </>
          </div>
          <div>
            <div>Month</div>
            <>
              <Select
                defaultValue="January"
                style={{
                  width: "fit-content",
                }}
                onChange={handleChange1}
                options={[
                  {
                    value: "January",
                    label: "January ",
                  },
                  {
                    value: "February",
                    label: "February ",
                  },
                  {
                    value: "March",
                    label: "March ",
                  },
                  {
                    value: "April",
                    label: "April ",
                  },
                  {
                    value: "May",
                    label: "May ",
                  },
                  {
                    value: "June",
                    label: "June ",
                  },
                  {
                    value: "July",
                    label: "July ",
                  },
                  {
                    value: "August",
                    label: "August ",
                  },
                  {
                    value: "September",
                    label: "September ",
                  },
                  {
                    value: "October",
                    label: "October ",
                  },
                  {
                    value: "November",
                    label: "November ",
                  },
                  {
                    value: "December",
                    label: "December ",
                  },
                ]}
              />
            </>
          </div>
        </div> */}
        {/* Info */}

        {/* Toolbar */}
        {/* <div className="d-flex align-items-center flex-wrap">
          <button
            type="button"
            className={`btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2`}
          >
            <span className="svg-icon svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            {` `}New Member
          </button>

          <QuickActions />

         //  Button 
          <button
            className="btn btn-primary btn-icon font-weight-bolds   "
            data-toggle="modal"
            data-target="#kt_chat_modal"
          >
            <span className="svg-icon svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group-chat.svg"
                )}
              />
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
}
