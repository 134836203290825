import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GetUsersMonthWise } from "../../../../services/ApiServices";

export function LineChart() {
  const [count, setCount] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);

  const getCount = async () => {
    let result;

    result = await GetUsersMonthWise();
    try {
      if (result?.success) {
        setCount(result.userData);
        // setYear(result.year);
        setMonth(result.labelData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCount();
  }, []);

  const data = {
    series: [
      {
        name: "Users",
        data: count,
        // data:[0,8,0,0,0,0,0,0,0,0,0,0]
      },
    ],
    options: {
      chart: {
        height: "250px",
        width: 100,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      // tooltip: {
      //   custom: function({ series, seriesIndex, dataPointIndex, w }) {
      //     return (
      //       '<div class="arrow_box">' +
      //       "<span>" +
      //       w.globals.labels[dataPointIndex] +
      //       ": " +
      //       series[seriesIndex][dataPointIndex] +
      //       "</span>" +
      //       "</div>"
      //     );
      //   },
      // },
      stroke: {
        width: 5,
        curve: "smooth",
      },
      xaxis: {
        categories: month,
      },
      title: {
        text: "Users",
        align: "left",
        style: {
          fontSize: "16px",
          color: "#666",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      yaxis: {
        min: 0,
        // max: 40
      },
    },
  };
  return (
    <div id="chart" >
      <ReactApexChart options={data.options} series={data.series} height={500}/>
    </div>
  );
}
