/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function TilesWidget17(
  props,
  { className, baseColor = "primary", widgetHeight = "150px" }
) {
  return (
    <>
      <div
        className={`card card-custom  ${className}`}
        style={{
          height: widgetHeight,
          backgroundImage: " linear-gradient(#ba5314c9,#ba5314c9)",
        }}
      >
        <div className="card-body">
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            />
          </span>
          <div
            className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}
          >
            {props.loading ? (
              <div
                className={`text-inverse-white spinner spinner-white font-weight-bolder font-size-h2 mb-3 mt-3 py-4`}
              ></div>
            ) : (
              props?.user?.user?.map((x, index) => (
                <div key={index}>
                  {x.count >= 1e3 && x.count < 1e6
                    ? (x.count / 1e3).toFixed(1) + "K"
                    : x.count >= 1e6 && x.count < 1e9
                    ? (x.count / 1e6).toFixed(1) + "M"
                    : x.count >= 1e9 && props?.user?.count < 1e12
                    ? (x.count / 1e9).toFixed(1) + "B"
                    : x?.count}
                </div>
              ))
            )}
          </div>

          <a
            href="admin/users"
            className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}
          >
            Customers
          </a>
        </div>
      </div>
    </>
  );
}
