import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import "./styles.css";

const PieChart = (props) => {
  ChartJS.register( Tooltip, Legend);

  const data = {
    labels: ["Coimbatore ", "Madurai", "Ramanathapuram", "Chennai", "Tirupur"],
    datasets: [
      {
        label: "#no of companies",
        data: [50, 30, 25, 50],
        backgroundColor: ["#6C00FF", "#3C79F5", "#2DCDDF", "#243763"],
        borderColor: ["#6C00FF"],
        borderWidth: 0,
      },
    ],
  };

  const chartData = {
    options: {
      labels: ["Coimbatore", "Madurai", "Chennai", "Tirupur"],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      colors: ["#3D1766", "#3C79F5", "#2DCDDF", "#243763"],
      responsive: [
        {
          breakpoint: 580,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
            },
          },
        },
      ],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // console.log(config.w.config.labels[config.dataPointIndex]);
            console.log("click");
            props.get(config.w.config.labels[config.dataPointIndex]);
          },
        },
      },
    },
    series: [50, 55, 17, 40],
  };

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
      />
    </div>
  );
};

export default PieChart;
