/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { getCounts } from "../../../../services/ApiServices";
import { toAbsoluteUrl } from "../../../_helpers";
import { BiCategory } from "react-icons/bi";

export function TilesWidget16(
  props,
  { className, iconColor = "success", widgetHeight = "150px" }
) {
  return (
    <>
      <div
        className={` card card-custom ${className}`}
        style={{
          height: widgetHeight,
          backgroundImage: " linear-gradient(#2dcbb4d1,#2dcbb4d1)",
        }}
      >
        <div className="card-body text-white">
          <span className="svg-icon svg-icon-3x  ml-n2 text-white">
            <BiCategory />
          </span>
          <div className="font-weight-bolder font-size-h2 mt-3">
            {props.loading ? (
              <div
                className={`text-inverse-white spinner spinner-white font-weight-bolder font-size-h2 mb-3 mt-3 py-4`}
              ></div>
            ) : (
              props.user?.category?.map((x, index) => (
                <div key={index}>
                  {x.count >= 1e3 && x.count < 1e6
                    ? (x.count / 1e3).toFixed(1) + "K"
                    : x.count >= 1e6 && x.count < 1e9
                    ? (x.count / 1e6).toFixed(1) + "M"
                    : x.count >= 1e9 && props?.user?.count < 1e12
                    ? (x.count / 1e9).toFixed(1) + "B"
                    : x?.count}
                </div>
              ))
            )}
          </div>

          <a
            href="admin/categories"
            className="text-white text-hover-primary font-weight-bold font-size-lg mt-1"
          >
            Categories
          </a>
        </div>
      </div>
    </>
  );
}
