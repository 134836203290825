import axios from "axios";

import {
  GetAdmin,
  Login,
  Register,
  SignatureLogin,
} from "../../../../services/ApiServices";

export const LOGIN_URL = `https://jxkh6l5zp8.execute-api.eu-west-1.amazonaws.com/prod/v1/admin/login`;
export const REGISTER_URL =
  "https://jxkh6l5zp8.execute-api.eu-west-1.amazonaws.com/prod/v1/admin";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export async function login(email, password) {
  let result;
  let body = {
    email: email,
    password: password,
  };
  try {
    result = await Login(body);
  } catch (e) {
    console.log(e);
    return e;
  }
  try {
    if (result.success) {
      return result;
    }
  } catch (e) {
    console.log(e);
    return e;
  }

  // return axios.post(LOGIN_URL, { email, password });
}
export async function registerByadmin(
  userName,
  email,
  password,
  investment,
  sale
) {
  let result;
  let body = {
    userName: userName,
    email: email,
    password: password,
    admin: true,
    artist: false,
    investment: investment,
    inventory: sale,
  };

  try {
    result = await Register(body);
  } catch (e) {
    console.log(e);
    return e;
  }
  try {
    if (result.success) {
      return result;
    }
  } catch (e) {
    console.log(e);
    return e;
  }

  // return axios.post(LOGIN_URL, { email, password });
}
export async function signatureLogin(signature) {
  let result;
  try {
    result = await SignatureLogin(signature);
  } catch (e) {
    console.log(e);
    return e;
  }
  try {
    if (result.success) {
      return result;
    }
  } catch (e) {
    console.log(e);
    return e;
  }

  // return axios.post(LOGIN_URL, { email, password });
}
export function register(body) {
  return axios.post(REGISTER_URL, { body });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  // return axios.get(ME_URL);
  let result;
  try {
    result = await GetAdmin(token);
  } catch (e) {
    console.log(e);
  }
  try {
    if (result.success) {
      return result;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
}
